import i18n from "@/i18n.js"

const columnDefs = [{
        headerName: `${i18n.t('form.table_id')}`,
        traslateName: "form.table_id",
        field: "id",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerCheckboxSelection: true,
        filterParams: {
            clearButton: true,
        },
        width: 25,
    },
    {
        headerName: `${i18n.t('form.user')}`,
        traslateName: "form.user",
        field: "username",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true,
        },
        width: 35,
    },
    {
        headerName: `${i18n.t('webhook.table_service')}`,
        traslateName: "webhook.table_service",
        field: "event",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true,
        },
        width: 35,
    },
    {
        headerName: `${i18n.t('webhook.table_endpoint')}`,
        traslateName: "webhook.table_endpoint", //
        field: "endpoint",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true,
        },
        width: 70,
    },
    {
        headerName: `${i18n.t('custom_email.table_date')}`,
        traslateName: "custom_email.table_date",
        field: "created_at",
        sortable: true,
        filter: "agDateColumnFilter",
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            comparator: (filterLocalDateAtMidnight, // eslint-disable-line
                cellValue) => { // eslint-disable-line
                let dateAsString = cellValue;
                if (dateAsString == null) return -1;
                let dateParts = dateAsString.split("/");
                let cellDate = new Date(
                    Number(dateParts[2]),
                    Number(dateParts[1]) - 1,
                    Number(dateParts[0])
                );
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                }
                if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                }
                if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                }
            },
            browserDatePicker: true,
        },
        width: 35,
    }
]

export default {
    columnDefs,
}