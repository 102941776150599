/* eslint-disable */
<template>
  <vs-popup id="popupNewForm" :title="$t('status.form')" :active.sync="popup">
    <div class="vx-row mb-4">
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <label class="vs-select--label">{{$t('form.user')}}</label>
        <v-select
          :options="users"
          label="name"
          :clearable="false"
          name="users"
          :data-vv-as="$t('users.company')"
          v-validate="'required'"
          v-model="user"
          autocomplete="off"
        >
          <div slot="no-options">{{$t('webhook.there_are_no_users')}}</div>
        </v-select>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <label class="vs-select--label">{{ $t('webhook.title') }}</label>
        <v-select
          :options="events"
          label="name"
          name="events"
          :data-vv-as="$t('webhook.title')"
          v-validate="'required'"
          :clearable="false"
          :placeholder="'events'"
          v-model="event"
          autocomplete="off"
        >
          <template slot="option" slot-scope="option">{{ option.name | capitalize }}</template>
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('estado')">{{ errors.first('estado') }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-3/5 mb-2 flex flex-col justify-center">
        <label class="vs-select--label">{{ $t('webhook.enter_endpoint') }}</label>
        <vs-input
          v-model="endpoint"
          :data-vv-as="$t('form.endpoint')"
          v-validate="{required:true, url: {require_protocol: true }}"
          name="endpoint"
        ></vs-input>
        <span
          class="text-danger text-sm"
          v-show="errors.has('endpoint')"
        >{{ errors.first('endpoint') }}</span>
      </div>
      <div class="vx-col sm:w-2/5 flex flex-col items-center">
        <label class="vs-select--label">{{ $t('webhook.need_token') }}</label>
        <vs-switch class="mt-4" color="primary" v-model="needToken"></vs-switch>
      </div>
    </div>
    <div class="vx-row" v-show="needToken">
      <div class="vx-col sm:w-2/3 mb-2 flex flex-col justify-center">
        <label class="vs-select--label">{{ $t('webhook.enter_token') }}</label>
        <vs-input v-model="auth_key"></vs-input>
      </div>
    </div>
    <div class="vx-row">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
        <vs-button
          class="mt-6 block offset-3"
          color="primary"
          type="filled"
          :disabled="!isSaved"
          @click="setSave"
        >{{$t('save')}}</vs-button>
      </vs-col>
    </div>
  </vs-popup>
</template>

<script>
/* eslint-disable */
import HTTP from "@/http-common";

export default {
  props: {
    popupActive: {
      type: Boolean,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      users: [],
      user: { id: null, name: null },
      events: [
        { id: 1, name: "CREATE" },
        { id: 2, name: "UPDATE" },
        { id: 3, name: "STATUS" },
      ],
      event: { id: null, name: null },
      endpoint: "",
      auth_key: "",
      needToken: false,
    };
  },
  computed: {
    popup: {
      get() {
        return this.popupActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup", {});
        }
      },
    },
    isSaved() {
      return !!this.user.id && !!this.event.id && !this.errors.has("endpoint");
    },
  },
  watch: {},
  methods: {
    async getUsers() {
      try {
        const response = await HTTP.get("/v1/webhooks/users");
        if (response.data.status == "success") {
          this.users = response.data.users.map((user) => ({
            id: user.id,
            name: user.username,
          }));
        } else {
          throw { message: "bad request" };
        }
      } catch (error) {
        console.log(error);
        this.$emit("closePopup", {});
      }
    },
    setSave() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("submitForm", {
            user_id: this.user.id,
            endpoint: this.endpoint,
            event: `service.${this.event.name.toLowerCase()}`,
            auth_key: this.needToken ? this.auth_key : null,
          });
          this.clear();
        }
      });
    },
    clear() {
      this.user = { id: null, name: null };
      this.event = { id: null, name: null };
      this.endpoint = "";
      this.auth_key = "";
      this.needToken = false;
    },
  },
  created() {
    if (!this.user.length) {
      this.getUsers();
    }
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupNewForm");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  },
};
</script>

<style scoped>
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vs-con-input-label {
  width: 350px !important;
}
</style>