<template>
  <vs-popup id="popupEditForm" :title="`Endpoint: ${user}`" :active.sync="popup">
    <div class="vx-row mb-4">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <label class="vs-select--label">{{ $t('webhook.title') }}</label>
        <v-select
          label="name"
          name="events"
          :data-vv-as="$t('webhook.title')"
          v-validate="'required'"
          :options="events"
          :clearable="false"
          :placeholder="$t('webhook.title')"
          v-model="own_event"
        >
          <template slot="option" slot-scope="option">{{ option.name | capitalize }}</template>
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('events')">{{ errors.first('events') }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <label class="vs-select--label">{{ $t('webhook.enter_endpoint') }}</label>
        <vs-input
          class="w-full"
          v-model="own_endpoint"
          :data-vv-as="$t('form.endpoint')"
          v-validate="{required:true, url: {require_protocol: true }}"
          name="endpoint"
        ></vs-input>
        <span
          class="text-danger text-sm"
          v-show="errors.has('endpoint')"
        >{{ errors.first('endpoint') }}</span>
      </div>
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <label class="vs-select--label">{{ $t('webhook.need_token') }}</label>
        <vs-switch class="mt-4" color="primary" v-model="needToken"></vs-switch>
      </div>
    </div>

    <div class="vx-row" v-show="needToken">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <label class="vs-select--label">{{ $t('webhook.enter_token') }}</label>
        <vs-input class="w-full" v-model="own_auth_key"></vs-input>
      </div>
    </div>
    <div class="vx-row">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
        <vs-button
          class="mt-6 block offset-3"
          color="primary"
          type="filled"
          :disabled="!isSaved"
          @click="setSave"
        >{{$t('save')}}</vs-button>
      </vs-col>
    </div>
  </vs-popup>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    popupActive: {
      type: Boolean,
      required: true,
    },
    auth_key: {
      type: [String, Object],
      required: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
    event: {
      type: String,
      required: false,
    },
    user: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      events: [
        { id: 1, name: "CREATE" },
        { id: 2, name: "UPDATE" },
        { id: 3, name: "STATUS" },
      ],
      own_endpoint: "",
      own_auth_key: "",
      own_event: { id: null, name: null },
      needToken: false,
    };
  },
  computed: {
    popup: {
      get() {
        return this.popupActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup", {});
        }
      },
    },
    isSaved() {
      return true;
    },
  },
  watch: {
    popup() {
      this.own_event.name = this.event;
      this.own_endpoint = this.endpoint;
      this.own_auth_key = this.auth_key;
      this.needToken = this.auth_key !== "" || !!this.auth_key;
    },
  },
  methods: {
    setSave() {
      this.$emit("submitForm", {
        endpoint: this.own_endpoint,
        event: `service.${this.own_event.name.toLowerCase()}`,
        auth_key: this.needToken ? this.own_auth_key : null,
      });
    },
  },
  mounted() {},
  beforeDestroy() {
    var elem = document.querySelector("#popupEditForm");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  },
};
</script>

<style scoped>
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>