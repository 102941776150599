<template>
  <div>
    <div class="vs-con-loading__container">
      <ag-grid-table
        :animateRows="true"
        :suppressRowClickSelection="false"
        :columnDefs="columnDefs"
        :rowData="webhooks"
        :gridOptions="gridOptions"
        rowSelection="single"
      >
        <template v-if="true" v-slot:actionBar>
          <ag-action-bar
            :is_add="$can('create', 'webhook')"
            :is_edit="$can('update', 'webhook')"
            :is_trash="$can('delete', 'webhook')"
            @add="onBtnAdd"
            @edit="onBtnEdit"
            @trash="onBtnTrash"
          ></ag-action-bar>
        </template>
      </ag-grid-table>
    </div>
    <vue-form-new
      ref="FormNewWebhook"
      :popupActive="popupNewActive"
      @submitForm="getDataFormNew"
      @closePopup="closeModal"
    ></vue-form-new>
    <vue-form-edit
      ref="FormEditWebhook"
      :popupActive="popupEditActive"
      :user="user_selected"
      :auth_key="auth_key_selected"
      :endpoint="endpoint_selected"
      :event="event_selected"
      @submitForm="getDataFormEdit"
      @closePopup="closeModal"
    ></vue-form-edit>
  </div>
</template>

<script>
import HTTP from "@/http-common";
import AgGridTable from "@/components/tableAgGrid/Index";
import ActionsBar from "@/components/tableAgGrid/ActionsBar";
import moment from "moment";
import FieldsWebhook from "@/fields/webhook/fieldsWebhook";
import FormNewWebhook from "@/components/webhook/FormNew";
import FormEditWebhook from "@/components/webhook/FormEdit";
const NAME = "IndexWebhook";
export default {
  name: NAME,
  components: {
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar,
    "vue-form-new": FormNewWebhook,
    "vue-form-edit": FormEditWebhook,
  },
  data() {
    return {
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` },
      },
      webhooks: [],
      popupNewActive: false,
      popupEditActive: false,
      columnDefs: FieldsWebhook.columnDefs,
      user_selected: "",
      endpoint_selected: "",
      auth_key_selected: "",
      event_selected: "",
      id_selected: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    onBtnAdd() {
      this.popupNewActive = true;
    },
    async onBtnEdit() {
      let data = this.gridOptions.api.getSelectedNodes();
      if (data.length === 1) {
        this.event_selected = data[0].data.event;
        this.auth_key_selected = data[0].data.auth_key;
        this.endpoint_selected = data[0].data.endpoint;
        this.user_selected = data[0].data.username;
        this.id_selected = data[0].data.id;
        this.popupEditActive = true;
      } else if (data.length > 1) {
        this.$vs.notify({
          text: this.$t("vehicles.selection_one"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    async onBtnTrash() {
      let data = this.gridOptions.api.getSelectedNodes();
      if (data.length === 1) {
        const response = await this.$swal({
          position: "center",
          type: "error",
          title: this.$t("danger"),
          text: this.$t("webhook.message_delete_webhook"),
          confirmButtonColor: "#ea5455",
          cancelButtonColor: "#24292e",
          showLoaderOnConfirm: true,
          showConfirmButton: true,
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: this.$t("delete"),
          cancelButtonText: this.$t("cancel"),
          preConfirm: (data) => data,
        });
        if (response.value) {
          try {
            await HTTP.delete(`v1/webhooks/${data[0].data.id}`);
            this.webhooks = this.webhooks.filter(
              (webhook) => webhook.id !== data[0].data.id
            );
          } catch (error) {
            return this.$swal({
              position: "center",
              type: "warning",
              confirmButtonColor: "#FDD402",
              title: "Oops...",
              text: error.response.data.message,
            });
          }
        }
      } else if (data.length > 1) {
        this.$vs.notify({
          text: this.$t("vehicles.selection_one"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    async requestWebhooks() {
      try {
        let webhooksGet = await HTTP.query("v1/webhooks");
        if (webhooksGet.data.status === "success") {
          this.webhooks = webhooksGet.data.webhooks.map((webhook) => ({
            id: webhook.id,
            username: webhook.username,
            event: webhook.event.split(".")[1].toUpperCase(),
            endpoint: webhook.endpoint,
            auth_key: webhook.auth_key ? webhook.auth_key : "",
            created_at: moment(webhook.created_at).format("DD/MM/YYYY"),
          }));
        } else {
          throw { message: "Error " };
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDataFormNew(formData) {
      this.closeModal();
      try {
        await HTTP.post("v1/webhooks", formData);
        await this.requestWebhooks();
        this.$swal({
          position: "center",
          type: "success",
          title: this.$t("custom_email.title_success_template"),
          confirmButtonColor: "#FDD402",
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          position: "center",
          type: "warning",
          confirmButtonColor: "#FDD402",
          title: "Oops...",
          text: error.response.data.error.message,
        });
      }
    },
    async getDataFormEdit(formData) {
      this.closeModal();
      try {
        await HTTP.put("v1/webhooks", { id: this.id_selected, ...formData });
        await this.requestWebhooks();
        this.$swal({
          position: "center",
          type: "success",
          title: this.$t("webhook.title_update"),
          confirmButtonColor: "#FDD402",
          allowOutsideClick: false,
          timer: 3000,
        });
      } catch (error) {
        this.$swal({
          position: "center",
          type: "warning",
          confirmButtonColor: "#FDD402",
          title: "Oops...",
          text: error.response.data.error.message,
        });
      }
    },
    closeModal() {
      this.popupNewActive = false;
      this.popupEditActive = false;
    },
  },
  async mounted() {
    await this.requestWebhooks();
  },
  created() {},
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.container-editor-email {
  display: flex;
  flex-direction: column;
  height: 700px;
}
</style>