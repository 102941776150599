<template >
  <webhook-index></webhook-index>
</template>

<script>
import WebhookIndex from "@/components/webhook/Index.vue";
import { validatePermission } from "@/Ability";

const COMPONENT_NAME = "ViewIndexWebhook";

export default {
  name: COMPONENT_NAME,
  components: {
    "webhook-index": WebhookIndex,
  },
  created() {
    validatePermission(this, "read", "webhook", "Home");
  },
};
</script>